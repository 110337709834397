import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import RegisterReducer from "./Reducers/RegisterReducer";
import {CustomerDetailsReducer} from "./Reducers/CustomerDetailsReducer";

const rootReducer = (history) => combineReducers({
        router: connectRouter(history),
        register: RegisterReducer,
        customerDetails: CustomerDetailsReducer
    }
);

export default rootReducer;
