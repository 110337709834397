import React from 'react'
import PropTypes from 'prop-types'

const ComponentContainerHeader = props => {
    const title= React.useRef(null);
    React.useEffect(()=>{
        title.current && title.current.focus();
    })
    return (
        <div ref={title}>
            <p className={"mydna-welcome-text"}>{props.primaryTitle}</p>
            {props.secondaryTitleLine2 ?
                <p style={{color: 'white'}}>
                    {props.secondaryTitle} <br />
                    {props.secondaryTitleLine2}
                </p>
            :
                <p style={{color: 'white'}}>
                    {props.secondaryTitle}
                </p>
            }
            
        </div>
    )
}

ComponentContainerHeader.propTypes = {
    primaryTitle:PropTypes.string,
    secondaryTitle: PropTypes.string,
    secondaryTitleLine2: PropTypes.string
}

export default ComponentContainerHeader
