import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CircularProgress from '@material-ui/core/CircularProgress';
import StyledButton from "../components/StyledButton";
const ComponentButtons = (props) => {
  return (
    <React.Fragment>
        <div className="inline-buttons" >
            <StyledButton
              disabled={props.isSubmitting}
              className={'SubmitButton'}
              type="submit"
              value="Submit"
              variant="contained"
              color="secondary"
            >
              {props.isSubmitting===true?
                  <CircularProgress size={25} color="secondary" />:props.submitText??<p style={{color:'black'}}>Next</p>}
            </StyledButton>
          </div><br/>
          {props.singleButton ? (
            <div></div>
          ) : (
            <div>
              <StyledButton
                className={'BackButton'}
                disabled={props.isSubmitting}
                disableElevation
                variant="contained"
                onClick={() => props.backOnClick?.()??props.dispatch(push(props.previousPage))}
              >
              {(props.disableArrows) ? '':<ArrowBackIosIcon fontSize={"small"} />}
                  { props.backText??'BACK'}
              </StyledButton>
            </div>
          )}
    </React.Fragment>
  );
};

ComponentButtons.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default ComponentButtons;
