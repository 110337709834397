import React, {Component} from "react";
import {connect} from 'react-redux';
import {Route, Switch, Redirect} from 'react-router'
import Amplify, {Auth} from "aws-amplify";
import {ThemeProvider} from "@material-ui/core/styles";
import './App.css';
import MenuAppBar from "./Shared/MenuAppBar";
import Authentication from "./Register/Authentication";
import {signedIn, signUp} from './Actions/RegisterActions'
import PrivateRoute from "./Shared/PrivateRoute";
import CircularIndeterminate from "./Shared/CircularIndeterminate";
import Notifier from "./Shared/Notifier";
import ErrorBoundary from "./Shared/ErrorBoundary";
import {PrivatePages} from "./Shared/Constants";
import ComponentWrapper from "./Shared/ComponentWrapper";
import {materialTheme} from "./Styles/materialTheme";

const awsConfig = require("./aws-exports.json");
const awsCognitoConfig = require("./aws-cognito-config.json");
const appSyncConfig = require("./aws-appsync-config.json");


const amplifyConfig = Object.assign({}, awsConfig, awsCognitoConfig);
Amplify.configure(amplifyConfig);
Amplify.configure(appSyncConfig);

require("dotenv").config();

class App extends Component {

    state = {
        isLoaded: false,
        isDev: false
    };

    componentDidMount() {
        window.localStorage.clear();

        Auth.currentAuthenticatedUser().then(user => {
            this.props.dispatch(signedIn(user));
        }).catch(e => {
            this.props.dispatch(signUp());
        }).finally(() => {
            this.setState({isLoaded: true});
        });
    }

    render() {
        return (<div>
                {this.state.isLoaded ? <div className="App">
                        <ThemeProvider theme={materialTheme}>
                            <ErrorBoundary>
                                <MenuAppBar {...this.props.register} dispatch={this.props.dispatch}/>
                                <Switch>
                                    {
                                        PrivatePages.map((page) =>
                                            <PrivateRoute key={page.key} path={page.path}>
                                                <ComponentWrapper component={page.component}
                                                    {...this.props.register}
                                                    {...this.props.customerDetails}
                                                    title={page.title}
                                                    nextPage={page.nextPage}
                                                    alternateExit={page?.alternateExit}
                                                    previousPage={page.previousPage}
                                                    dispatch={this.props.dispatch}
                                                    stepNum ={page.stepNum}
                                                    />
                                            </PrivateRoute>
                                        )
                                    }
                                    {/* Redirecting to confirmation if Authentication is successful */}
                                    {
                                        this.props.register.auth.isAuthenticated === true ? (
                                            <Redirect to="/confirmation"/>):(
                                        <Route exact path="/">
                                            <Authentication {...this.props.register} dispatch={this.props.dispatch}/>
                                        </Route>
                                        )
                                    }
                                    
                                </Switch>
                            </ErrorBoundary>
                        </ThemeProvider>
                    </div> :
                    <CircularIndeterminate/>
                }
                <Notifier/>
            </div>
        );
    }
}

export default connect(mapStateToProps)(App);

function mapStateToProps(store) {
    return {
        register: store.register,
        customerDetails: store.customerDetails,
        router: store.router,
        stepNum : store.customerDetails.stepNum 
    };
}
