import { CustomerServiceEndpoints } from "./HttpConstants";
import { CUSTOMER_MICROSERVICE_BASE_URL, UNIVERSALPRACTICE_VIP_SUBSCRIPTION_CODE } from "../Shared/Configs";

export async function updateCustomerDetails(store) {
  //Adding UniversalPracticeUser as the user as DynamoDb needs last name and the call fails.
  let customerDetails = {
    lastName: 'UniversalPracticeUser',
    emailAddress: store.auth.user.username,
    timeZone: store.userDetails.timeZone,
    promoCode: store?.couponDetails?.promoCode ?? null,
    subscriptionCode: store?.initialValue.subscription.subscriptionOptionId,
    recurringPaymentPriceId: store?.initialValue?.subscription?.subscriptionOptionPriceId
  }
  return callUpdateRegistrationDetails(customerDetails);
}

async function callUpdateRegistrationDetails(customerDetails) {
  return fetch(
    `${CUSTOMER_MICROSERVICE_BASE_URL}${CustomerServiceEndpoints.UpdateRegistrationDetails}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(customerDetails),
    }
  );
}

export async function commitCustomerDetails(details) {
  let customerDetails = {
    EmailAddress: details.emailAddress,
    FirstName: details.firstName,
    LastName: details.lastName,
    Country: 'Australia',
    SubscriptionCode: UNIVERSALPRACTICE_VIP_SUBSCRIPTION_CODE
  }
  return callCommitRegistrationDetails(customerDetails);
}

async function callCommitRegistrationDetails(customerDetails) {
  return fetch(
    `${CUSTOMER_MICROSERVICE_BASE_URL}${CustomerServiceEndpoints.CommitRegistrationDetails}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(customerDetails),
    }
  );
}