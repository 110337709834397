import { responsiveFontSizes, createMuiTheme } from "@material-ui/core";

const colours = {
  primaryMain: "#000",
  secondaryMain: "#FFF",
  secondaryLight: "#000",
  titleDark: "#000",
  border: "#000",
};

const typography = {
  fontFamily: "Moderat"
}

export const materialTheme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      ...typography
    },
    palette: {
      primary: {
        main: colours.primaryMain,
      },
      secondary: {
        main: colours.secondaryMain,
        light: colours.secondaryLight,
      },
    },
  })
);
