import { CustomerDetailsActions } from "../Actions/CustomerDetailsActions";
import { AppLinkURLS } from "../Shared/AppLinkURLS";
import { getSubscriptionDetails } from "../HttpHelpers/CustomerServiceHttpHelper";

const initialValue = {
  state: CustomerDetailsActions.PERSONAL_DETAILS,
  error: {},
  userDetails: {},
  subscription: null,
  stepNum: 0,
  subcriptionOptions: null,
  couponDetails: null,
  payment: null,
};

let timeZone = "Australia/Sydney";
if (window.Intl && typeof window.Intl === "object") {
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
}
initialValue.userDetails['timeZone'] = timeZone;

export const selectApplinkURL = (partner = "DEFAULT") => {
  partner = partner ?? "DEFAULT";
  var country = initialValue.place?.country?.toUpperCase() ?? "DEFAULT";
  var url = AppLinkURLS.find(element => ((element.Country.toUpperCase() === country.toUpperCase()) && (element.Partner.toUpperCase() === partner.trim().toUpperCase())));
  url = url ?? AppLinkURLS.find(element => (element.Country === "DEFAULT" && element.Partner === "DEFAULT"));
  return url;
}

export function CustomerDetailsReducer(
  state = {
    initialValue,
  },
  action
) {
  if (!Object.values(CustomerDetailsActions).includes(action.type))
    return state;

  if (action.payload?.userDetails) {
    initialValue.userDetails = action.payload.userDetails;
  }

  if (action.payload?.stepNum) {
    initialValue.stepNum = action.payload.stepNum;
  }

  if (action.payload?.subscription)
    initialValue.subscription = action.payload.subscription;

  if (action.payload?.couponDetails)
    initialValue.couponDetails = action.payload.couponDetails;

  if (action.payload?.payment)
    initialValue.payment = action.payload.payment;

  return Object.assign({}, state, initialValue);
}
