import React, {Component} from 'react';
import StepperComponent  from "./StepperComponent";
class ComponentWrapper extends Component {
    
    render() {
        return(
            <div>
                <div className="title">
                    {this.props.title}
                </div>
                <StepperComponent stepNum ={this.props.stepNum }/>
                {React.createElement(this.props.component, this.props)}
            </div>
        );
    }
}

export default ComponentWrapper;
