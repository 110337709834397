import {notify} from "../Shared/Notifier";
import {NotificationSeverity} from "../Shared/Constants";

export const RegisterActions = {
    SIGN_UP: 'signUp',
    SIGNED_UP: 'signedUp',
    SIGN_UP_FAILURE: 'signUp_failure',
    CONFIRM_SIGN_UP: 'confirmSignUp',
    SIGN_IN: 'signIn',
    SIGNED_IN: 'signedIn',
    SIGN_IN_FAILURE: 'signIn_failure',
    CONFIRM_SIGN_UP_FAILURE: 'confirmSignUp_failure',
    SIGN_OUT: 'signOut',
    SIGNED_OUT: 'signedOut',
    FORGOT_PASSWORD: 'forgotPassword',
    FORGOT_PASSWORD_FAILURE: 'forgotPasswordFailure',
    REQUIRE_NEW_PASSWORD:"requireNewPassword"
};

export const signUp = () => dispatch => {
    dispatch({
        type: RegisterActions.SIGN_UP,
        error: null,
        payload: {
            auth: {
                user: {},
                isAuthenticated: false
            }
        }
    })
};

export function signUpFailure(error) {
    return dispatch => {
        clearLocalStorage();
        dispatch({
                type: RegisterActions.SIGN_UP_FAILURE,
                error: error,
                payload: {
                    auth: {
                        user: {},
                        isAuthenticated: false
                    }
                }
            }
        );
    }
}

export function confirmSignUp(message) {
    return dispatch => {
        dispatch({
                type: RegisterActions.CONFIRM_SIGN_UP,
                error: null,
                message: message,
                payload: {
                    auth: {
                        user: {},
                        isAuthenticated: false
                    }
                }
            }
        );
        notify(message, NotificationSeverity.SUCCESS);
    }
}

export function signIn() {
    return dispatch => {
        dispatch({
                type: RegisterActions.SIGN_IN,
                payload: {
                    auth: {
                        user: {},
                        error: null,
                        isAuthenticated: false,
                        stepNum: 3
                    }
                }
            }
        )
    }
}

export function signedIn(user) {
    return dispatch => {
        localStorage.setItem('JwtToken', user.signInUserSession.accessToken.jwtToken);
        localStorage.setItem('RefreshToken', user.signInUserSession.refreshToken.token);
        dispatch({
                type: RegisterActions.SIGNED_IN,
                error: null,
                payload: {
                    auth: {
                        user: user,
                        isAuthenticated: true
                    }
                }
            }
        )
    }
}

export function signInFailure(error) {
    return dispatch => {
        clearLocalStorage();
        dispatch({
                type: RegisterActions.SIGN_IN_FAILURE,
                error: error,
                payload: {
                    auth: {
                        user: {},
                        isAuthenticated: false
                    }
                }
            }
        );
    }
}

export function confirmSignUpFailure(error) {
    return dispatch => {
        clearLocalStorage();
        dispatch({
                type: RegisterActions.CONFIRM_SIGN_UP_FAILURE,
                error: error,
                payload: {
                    auth: {
                        user: {},
                        isAuthenticated: false
                    }
                }
            }
        );
    }
}

export function signOut() {
    return dispatch => {
        clearLocalStorage();
        dispatch({
                type: RegisterActions.SIGN_OUT,
                error: null,
                payload: {
                    auth: {
                        user: {},
                        isAuthenticated: false
                    }
                }
            }
        )
    }
}

export function forgotPassword(forgotPassword) {
    return dispatch => {
        dispatch({
                type: RegisterActions.FORGOT_PASSWORD,
                error: null,
                payload: {
                    auth: null,
                    forgotPassword:forgotPassword
                }
            }
        );
    }
}

function clearLocalStorage() {
    localStorage.setItem('JwtToken', null);
    localStorage.setItem('RefreshToken', null);
}

