import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {AppBar, Toolbar} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    appbar:{
        backgroundColor: '#000'
    },
    logo:{
        margin:'auto',
        marginTop: 10,
        width: 220,
        height: 85
    }
}));

export default function MenuAppBar(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appbar}>
                <Toolbar>
                    <img src={require('../Assets/Universal_Practice_CMYK_White-300-100.png')} alt={'Universal_Practice_CMYK_White'} className={classes.logo} />
                </Toolbar>
            </AppBar>
        </div>
    );
}
