import React from "react";
import { Auth } from 'aws-amplify';
import { SignUp } from "aws-amplify-react";
import { confirmSignUp, RegisterActions, signedIn, signIn, signUpFailure } from "../Actions/RegisterActions";
import { userDetailsCaptured } from "../Actions/CustomerDetailsActions";
import { messages } from "../Shared/Messages";
import { AuthenticationTitle, PrivacyPolicyLink, TermsConditionsLink } from "../Shared/Constants";
import { PrimaryTitles, SecondaryTitles } from "../Shared/TitleTexts";
import ComponentContainerHeader from "../Shared/ComponentContainerHeader";
import ComponentButtons from "../Shared/ComponentButtons";
import AuthenticationStepper from "../Shared/AuthenticationStepper";
import CheckboxInput from "../Shared/CheckboxInput";
import StyledTextField from "../components/StyledTextField.js";
import { GetB2BPartners } from '../HttpHelpers/SubscriptionServiceHttpHelper';
export default class MyDnaSignUp extends SignUp {

    constructor(props) {
        super(props);

        this._validAuthStates = [RegisterActions.SIGN_UP];
        this.state = {
            privacyPolicy: false
        }
    }

    signUp(e) {
        e.preventDefault();

        this.setState({isSubmitting: true});

        if (this.state.privacyPolicy) {
            const firstName = this.inputs.firstName?.trim()
            const lastName = this.inputs.lastName?.trim()
            const email = this.inputs.email?.trim()
            const password = this.inputs.password?.trim()

            if (password !== this.inputs.confirmPassword?.trim()) {
                this.setState({
                    errorMessage: messages.passwordDoesNotMatch
                })
                this.props.dispatch(signUpFailure(messages.passwordDoesNotMatch));
                return;
            }

            // Verify that the email address domain is a valid b2b partner of Universal Practice
            var emailValid = false;
            GetB2BPartners("Universal Practice")
            // Uncomment the following line when we aren't using hardcoded values
            //.then(response => response.json())
            .then(data => {
                var userEmailSplit = email.split("@");

                if (userEmailSplit.length != 2) {
                    this.setState({
                        errorMessage: messages.pleaseCheckYourEmail
                    })
                } else {
                    var userEmailDomain = userEmailSplit[1];

                    for (const index in data) {
                        var b2bPartner = data[index];
                        // Uncomment the following line when we aren't using hardcoded values
                        //var b2bPartnerDomain = b2bPartner["domain"];
                        var b2bPartnerDomain = b2bPartner.Domain;
                        if (b2bPartnerDomain == userEmailDomain) {
                            emailValid = true;
                            break;
                        }
                    }
                }

                if (emailValid) {
                    Auth.signUp({
                        username: email,
                        password: password,
                        attributes: {
                            email: email
                        },
                    })
                        .then(data => {
                            this.setState({
                                errorMessage: null
                            })
                                if (!data.userConfirmed) {
                                    var userDetails = {
                                        emailAddress: email,
                                        firstName: firstName,
                                        lastName: lastName
                                    }
                                    this.props.dispatch(userDetailsCaptured(userDetails))
                                    super.changeState(RegisterActions.CONFIRM_SIGN_UP, { username: email, password: password });
                                    this.props.dispatch(confirmSignUp(messages.pleaseCheckYourEmail));
                                }
                                else {
                                    Auth.signIn({username: email, password}).then( user => {
                                        user.firstName = firstName;
                                        user.lastName = lastName;
                                        this.props.dispatch(signedIn(user));                            
                                        super.changeState(RegisterActions.SIGNED_IN);    
                                    })
                                }
                        })
                        .catch(err => {
                            if (err.code === "UsernameExistsException") {
                                this.props.dispatch(signUpFailure(messages.userExistsErrorMessage));
                                this.setState({
                                    errorMessage: messages.userExistsErrorMessage
                                })
                            } else if (err.code === "InvalidPasswordException") {
                                this.setState({
                                    errorMessage: messages.invalidPassword
                                })
                            }
                            else if (err.code === "InvalidParameterException") {
                                if (err.message.includes("password")) {
                                    this.setState({
                                        errorMessage: messages.invalidPassword
                                    })
                                } else {
                                    this.setState({
                                        errorMessage: err.message
                                    })
                                }
                            }
                            else {
                                this.setState({
                                    errorMessage: "Sign up failed"
                                })
                                this.props.dispatch(signUpFailure(err.message));
                            }
                        });
                } else {
                    this.setState({
                        errorMessage: messages.invalidB2BPartnerDomain
                    })
                }
            });
            
        } else {
            this.setState({
                errorMessage: messages.acceptPrivacyPolicy,
            });
        }
    }

    signIn() {
        super.changeState(RegisterActions.SIGN_IN);
        this.props.dispatch(signIn());
    }

    showComponent(theme) {
        if (this.state.errorMessage) this.state.isSubmitting = false;

        return (
            <form onSubmit={(e) => this.signUp(e)} className={"mydna-signup-form"}>
                <AuthenticationStepper title={AuthenticationTitle.SignUp.title} progress={AuthenticationTitle.SignUp.progress} />
                <ComponentContainerHeader primaryTitle={PrimaryTitles.Create} secondaryTitle={SecondaryTitles.Create} />
                <div style={{ width: '20rem', margin: 'auto' }}>
                    <div>
                        <StyledTextField
                            id="firstName"
                            title="firstName"
                            name="firstName"
                            onChange={this.handleInputChange}
                            required={true}
                        />
                    </div>
                    <div>
                        <StyledTextField
                            id="lastName"
                            title="lastName"
                            name="lastName"
                            onChange={this.handleInputChange}
                            required={true}
                        />
                    </div>
                    <div>
                        <StyledTextField
                            id="email"
                            title="email"
                            name="email"
                            onChange={this.handleInputChange}
                            required={true}
                        />
                    </div>
                    <div>
                        <StyledTextField
                            id="password"
                            title="password"
                            name="password"
                            type="password"
                            onChange={this.handleInputChange}
                            required={true}
                        />
                        <p className={'password-help-text'}>{messages.invalidPassword}</p>
                    </div>
                    <div>
                        <StyledTextField
                            id="confirmPassword"
                            title="Confirm Password"
                            name="confirmPassword"
                            onChange={this.handleInputChange}
                            type="password"
                            required
                        />
                    </div>
                    <div className="terms-conditions-container">
                        <CheckboxInput
                            name={"termsAndConditions"}
                            onChange={(event) =>
                                this.setState({
                                    privacyPolicy: !this.state.privacyPolicy,
                                    errorMessage: null,
                                })
                            }
                        />
                        <span className={"terms-conditions-font"}>
                            I accept Universal Practice's{" "}
                            <a
                                href={PrivacyPolicyLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={"TermsAndConditionsLink"}
                            >
                                Privacy Policy
                            </a>
                            {" "}and{" "}
                            <a
                                href={TermsConditionsLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={"TermsAndConditionsLink"}
                            >
                                Terms and Conditions
                            </a>
                        </span>
                    </div>
                    {this.state.errorMessage && <div>
                        <p className="error">{this.state.errorMessage}</p>
                    </div>}
                    <ComponentButtons
                        dispatch={this.props.dispatch}
                        previousPage={this.props.previousPage}
                        singleButton={true}
                        isSubmitting={this.state.isSubmitting}
                        submitText={"CREATE ACCOUNT"}
                        disableArrows={true} 
                        />
                </div>
            </form>
        );
    }
}
