import LocalizedStrings from "react-localization";

export const messages = new LocalizedStrings({
  en: {
    loginFailed: "Login failed, Please try again.",
    passwordDoesNotMatch: "Password doesn't match.",
    pleaseCheckYourEmail: "Please check your email.",
    confirmationSuccessful:
      "Confirmation was successful, You will be redirected soon.",
    codeResent: "Code resent. Please check your email.",
    invalidCardNumber: "Card Number Invalid, Please check and try again.",
    userExistsErrorMessage:
      "An account with this email already exists. Please try logging in. Alternatively, reset your password.",
    invalidPassword:
      "Password should contain minimum of 8 characters and an upper case, lower case characters.",
    passwordResetCode:
      "If you have an account, verfication code has been sent to your email.",
    changedPassword:
      "Password change successful please login with new credentials.",
    userNotFound: "Username not found.",
    CodeMismatchException:
      "Invalid verification code provided, please try again.",
    signUpFailed: "Sign up failed, please try again.",
    userNotConfirmedException: "Please confirm you email address",
    notAuthorizedException: "Incorrect username or password.",
    acceptTermsandConditions: "Please accept the Terms and Conditions.",
    acceptPrivacyPolicy: "Please accept the Privacy Policy.",
    underAgeUser: "myDNA is only available to people 18 and over.",
    InvalidParameterException:
      "Cannot reset password for the user as there is no registered/verified email or Phone number.",
    invalidB2BPartnerDomain: "Invalid partner domain",
    canotFindCustomerServiceException: "Error connecting to customer service. Please contact support."
  },
});
