import React from "react";
import StepperComponent from "./StepperComponent";
const AuthenticationStepper = (props) => {
  return (
    <div>
      <div className="title">{props.title}</div>
      <StepperComponent stepNum ={props.progress} />
    </div>
  );
};

export default AuthenticationStepper;
