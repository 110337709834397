import { SubscriptionServiceEndpoints } from "./HttpConstants";
import { SUBSCRIPTION_SERVICE_BASE_URL } from "../Shared/Configs";

export async function GetB2BPartners(partnerName) {

    // Todo: Remove
    // Hard coded response until we can access Subscription Management Portal without requiring an API key
    
    var responseObject = 
        [
            {
                "Domain": "genebygene.com",
                "CompanyName": "GeneByGene",
                "Partner": "Universal Practice"
            },
            {
                "Domain": "findex.com.au",
                "CompanyName": "Findex",
                "Partner": "Universal Practice"
            },
            {
                "Domain": "findex.co.nz",
                "CompanyName": "Findex NZ",
                "Partner": "Universal Practice"
            },
            {
                "Domain": "crowe.com.au",
                "CompanyName": "Crowe Australasia",
                "Partner": "Universal Practice"
            },
            {
                "Domain": "broadsheet.com.au",
                "CompanyName": "Broadsheet",
                "Partner": "Universal Practice"
            }
        ];
    return responseObject;
    
    let encodedPartnerName = encodeURIComponent(partnerName);
    return fetch(`${SUBSCRIPTION_SERVICE_BASE_URL}${SubscriptionServiceEndpoints.GetB2BPartner}?partnerName=${encodedPartnerName}`)
}