import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputBase } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    textField: {
        border: "1px solid white",
        borderRadius: theme.shape.borderRadius,
        height: "50px",
        width: "100%",
        backgroundColor: "black",
        color: "white",
        paddingLeft: 10,
        paddingRight: 10
    }
}));


export default function StyledTextField({ title, name, onChange, type, helperText, error, value, required }) {
    const classes = useStyles();
    // the current global font Moderat results in very small password type text
    const passwordStyle = type === "password" ? 
    { fontFamily: "roboto, sans-serif, Arial" } : {}
    return (
        <div>
            <p className="input-label">{title}</p>
            <InputBase
                id="outlined-basic"
                className={classes.textField}
                name={name}
                onChange={onChange}
                type={type}
                style={passwordStyle}
                value={value}
                required={required}
                inputProps={{ maxLength: 40 }}
            />
            {error && <p className="error small">{helperText}</p>}
        </div>
    );
}
