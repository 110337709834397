import MyDnaSignUp from "./MyDnaSignUp";
import MyDnaConfirmSignUp from "./MyDnaConfirmSignUp";
import Authenticator from "aws-amplify-react/lib/Auth/Authenticator";
import React, {Component} from "react";
import {RegisterActions} from "../Actions/RegisterActions";
export default class Authentication extends Component {
    render() {
        return (
            <div>
                <Authenticator
                    authState={RegisterActions.SIGN_UP}
                    usernameAttributes='email'
                    hideDefault={true}>
                    <MyDnaSignUp override={RegisterActions.SIGN_UP} auth={this.props.auth}
                        dispatch={this.props.dispatch}/>
                    <MyDnaConfirmSignUp override={RegisterActions.CONFIRM_SIGN_UP} auth={this.props.auth}
                        dispatch={this.props.dispatch}/>
                </Authenticator>
            </div>
        )
    }
}
