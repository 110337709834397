export const CustomerDetailsActions = {
  USER_DETAILS: "userDetails"
};

export const userDetailsCaptured = (userDetails) => (dispatch) => {
  dispatch({
    type: CustomerDetailsActions.USER_DETAILS,
    error: null,
    payload: {
      userDetails: userDetails
    },
  });
};
